import { ChainId } from '@pancakeswap/sdk'
import memoize from 'lodash/memoize'

export enum NonEvmChains {
  Solana = 'Solana',
}

export const ChainDisableFlag = {
  // [ChainId.POLYGON]: false,
  // [ChainId.AMOY]: false,
  // [ChainId.ETHEREUM]: false,
  [ChainId.SEPOLIA]: false,
  // [ChainId.BSC]: false,
  [ChainId.BSC_TESTNET]: false,
}

export const chainDisplayNames: Record<ChainId, string> = {
  // [ChainId.ETHEREUM]: 'Ethereum Mainnet',
  // [ChainId.BSC]: 'BNB Chain',
  // [ChainId.POLYGON]: 'Polygon',
  [ChainId.SEPOLIA]: 'Sepolia',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  // [ChainId.AMOY]: 'Amoy',
}

export const chainNames: Record<ChainId, string> = {
  // [ChainId.POLYGON]: 'polygon',
  // [ChainId.AMOY]: 'amoy',
  // [ChainId.ETHEREUM]: 'eth',
  [ChainId.SEPOLIA]: 'sepolia',
  // [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
}

export const CHAIN_NAME_TO_ID: Record<string, number> = {
  // polygon: ChainId.POLYGON,
  // amoy: ChainId.AMOY,
  // eth: ChainId.ETHEREUM,
  sepolia: ChainId.SEPOLIA,
  // bsc: ChainId.BSC,
  bscTestnet: ChainId.BSC_TESTNET,
}

export const chainSelectList = [
  // {
  //     chainId: ChainId.POLYGON,
  //     displayName: chainDisplayNames[ChainId.POLYGON],
  //     evm: true,
  //     mainnet: true,
  //     rpc: "https://polygon-rpc.com/",
  //     name: "Polygon",
  //     symbol: "MATIC",
  //     decimals: 18,
  //     explorer: "https://polygonscan.com"
  // },
  // {
  //     chainId: ChainId.ETHEREUM,
  //     displayName: chainDisplayNames[ChainId.ETHEREUM],
  //     evm: true,
  //     mainnet: true,
  //     rpc: "https://mainnet.infura.io/v3/",
  //     name: "Ethereum Mainnet",
  //     symbol: "ETH",
  //     decimals: 18,
  //     explorer: "https://etherscan.io"
  // },
  // {
  //     chainId: ChainId.BSC,
  //     displayName: chainDisplayNames[ChainId.BSC],
  //     evm: true,
  //     mainnet: true,
  //     rpc: "https://bsc-dataseed.binance.org/",
  //     name: "BNB Smart Chain",
  //     symbol: "BNB",
  //     decimals: 18,
  //     explorer: "https://bscscan.com"
  // },
  // {
  //     chainId: ChainId.SEPOLIA,
  //     displayName: chainDisplayNames[ChainId.SEPOLIA],
  //     evm: false,
  //     mainnet: true
  // },
  // {
  //     chainId: ChainId.AMOY,
  //     displayName: chainDisplayNames[ChainId.AMOY],
  //     evm: true,
  //     mainnet: true,
  //     rpc: "https://rpc-amoy.polygon.technology",
  //     name: "Polygon Amoy",
  //     symbol: "MATIC",
  //     decimals: 18,
  //     explorer: "https://amoy.polygonscan.com"
  // },
  {
    chainId: ChainId.SEPOLIA,
    displayName: chainDisplayNames[ChainId.SEPOLIA],
    evm: true,
    mainnet: true,
    rpc: 'https://eth-sepolia.api.onfinality.io/public',
    // rpc: 'https://sepolia.publicgoods.network',
    name: 'Sepolia Testnet',
    symbol: 'sETH',
    decimals: 18,
    explorer: 'https://sepolia.etherscan.io/',
  },
  // {
  //   chainId: ChainId.BSC_TESTNET,
  //   displayName: 'BSC Testnet',
  //   evm: true,
  //   mainnet: true,
  //   rpc: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
  //   name: 'Binance Testnet',
  //   symbol: 'tBNB',
  //   decimals: 18,
  //   explorer: 'https://testnet.bscscan.com/',
  // },
]

export const CHAIN_IDS: ChainId[] = [
  // ChainId.POLYGON,
  // ChainId.AMOY,
  // ChainId.ETHEREUM,
  ChainId.SEPOLIA,
  // ChainId.BSC,
  ChainId.BSC_TESTNET,
]

export const mainnetChainIds: ChainId[] = [
  // ChainId.POLYGON, ChainId.ETHEREUM, ChainId.BSC
]
export const testnetChainIds: ChainId[] = [
  // ChainId.AMOY,
  ChainId.SEPOLIA,
  ChainId.BSC_TESTNET,
]

export const isChainSupported = memoize((chainId: number) => (CHAIN_IDS as number[]).includes(chainId))
export const isChainTestnet = memoize((chainId: number) => testnetChainIds.includes(chainId as ChainId))

export const getChainIdTypeFromID = (id: number): ChainId => {
  if (Object.values(ChainId).includes(id)) {
    return id as ChainId
  }

  throw new Error('Unsupported Chains')
}

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

export const chainNameConverter = (name: string) => {
  switch (name) {
    case 'BNB Smart Chain':
      return 'BNB Chain'
    case 'Polygon Amoy':
      return 'Amoy'
    case 'Binance Smart Chain Testnet':
      return 'BSC Testnet'
    case 'polygon':
      return 'Polygon'
    case 'ethereum':
      return 'Ethereum'
    default:
      return name
  }
}
