import { ChainId } from '@pancakeswap/sdk'
import tokens from './tokens'
import { FarmConfig } from './types'
import ContractAddress from './contracts'

const priceHelperLps: FarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absense of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: 1,
    lpSymbol: 'Pizzaswap-WETH LP',
    lpAddresses: {
      // [ChainId.ETHEREUM]: String(ContractAddress.PcnLp[ChainId.ETHEREUM]),
      [ChainId.SEPOLIA]: String(ContractAddress.PcnLp[ChainId.SEPOLIA]),
      // [ChainId.BSC]: String(ContractAddress.PcnLp[ChainId.BSC]),
      [ChainId.BSC_TESTNET]: String(ContractAddress.PcnLp[ChainId.BSC_TESTNET]),
      // [ChainId.POLYGON]: String(ContractAddress.PcnLp[ChainId.POLYGON]),
      // [ChainId.AMOY]: String(ContractAddress.PcnLp[ChainId.AMOY]),
    },
    token: tokens.pcn,
    quoteToken: tokens.weth,
  },
  {
    pid: 2,
    lpSymbol: 'USDT-WETH LP',
    lpAddresses: {
      // [ChainId.ETHEREUM]: String(ContractAddress.BusdLp[ChainId.ETHEREUM]),
      [ChainId.SEPOLIA]: String(ContractAddress.BusdLp[ChainId.SEPOLIA]),
      // [ChainId.BSC]: String(ContractAddress.BusdLp[ChainId.BSC]),
      [ChainId.BSC_TESTNET]: String(ContractAddress.BusdLp[ChainId.BSC_TESTNET]),
      // [ChainId.POLYGON]: String(ContractAddress.BusdLp[ChainId.POLYGON]),
      // [ChainId.AMOY]: String(ContractAddress.BusdLp[ChainId.AMOY]),
    },
    token: tokens.busd,
    quoteToken: tokens.weth,
  },
]

export default priceHelperLps
