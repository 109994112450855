import { ChainId } from '@pancakeswap/sdk'
import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'
import ContractAddress from './contracts'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.pcn,
    earningToken: tokens.pcn,
    contractAddress: {
      // [ChainId.ETHEREUM]: String(ContractAddress.masterChef[ChainId.ETHEREUM]),
      [ChainId.SEPOLIA]: String(ContractAddress.masterChef[ChainId.SEPOLIA]),
      // [ChainId.BSC]: String(ContractAddress.masterChef[ChainId.BSC]),
      [ChainId.BSC_TESTNET]: String(ContractAddress.masterChef[ChainId.BSC_TESTNET]),
      // [ChainId.POLYGON]: String(ContractAddress.masterChef[ChainId.POLYGON]),
      // [ChainId.AMOY]: String(ContractAddress.masterChef[ChainId.AMOY]),
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    depositFee: '1',
    lockup: '1800',
  },
]

export default pools
