import { ChainId } from '@pancakeswap/sdk'

export default {
  networkId: localStorage.getItem('CurrentChain') || process.env.REACT_APP_CHAIN_ID,
  router: {
    [ChainId.SEPOLIA]: '0x6AE963C19Ff1c74605578891fcFe901c2754766f',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_FACTORY,
    // [ChainId.BSC]: process.env.REACT_APP_FACTORY,
    [ChainId.BSC_TESTNET]: '0x93B9d256856bE8eba7152Bdd53006a99CE9F13be',
    // [ChainId.POLYGON]: process.env.REACT_APP_FACTORY,
    // [ChainId.AMOY]: process.env.REACT_APP_FACTORY,
  },
  factory: {
    [ChainId.SEPOLIA]: '0x685293B37A1176AcD559CeFAA30002dAD837aDD0',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_FACTORY,
    // [ChainId.BSC]: process.env.REACT_APP_FACTORY,
    [ChainId.BSC_TESTNET]: '0x789FEc2EC24ed69049f7b8e5dDC3ce3e5ac7F157',
    // [ChainId.POLYGON]: process.env.REACT_APP_FACTORY,
    // [ChainId.AMOY]: process.env.REACT_APP_FACTORY,
  },
  masterChef: {
    [ChainId.SEPOLIA]: '0x24fdD3129177cc55661E6dc6a51fCE52d7584566',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_MASTERCHEF,
    // [ChainId.BSC]: process.env.REACT_APP_MASTERCHEF,
    [ChainId.BSC_TESTNET]: '0xC66545726f818316631cC943B9EE175b2Cd46cEe',
    // [ChainId.POLYGON]: process.env.REACT_APP_MASTERCHEF,
    // [ChainId.AMOY]: process.env.REACT_APP_MASTERCHEF,
  },
  sousChef: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  lotteryV2: {
    [ChainId.SEPOLIA]: '0x867638a3Be262A12b3A954845a37B19C1f9c3344',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_LOTTERY,
    // [ChainId.BSC]: process.env.REACT_APP_LOTTERY,
    [ChainId.BSC_TESTNET]: '0x746f8482eC574D2005058F1F5E0B8d22bac8Ba87',
    // [ChainId.POLYGON]: process.env.REACT_APP_LOTTERY,
    // [ChainId.AMOY]: process.env.REACT_APP_LOTTERY,
  },
  multiCall: {
    [ChainId.SEPOLIA]: '0x24CC186F2F762Ec41f40b0Fea9297B9D3283b5c3',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_MULTICALL,
    // [ChainId.BSC]: process.env.REACT_APP_MULTICALL,
    [ChainId.BSC_TESTNET]: '0x4f0DDC0D67a3FF19F1d0bD0D17eF23da10e2Bff3',
    // [ChainId.POLYGON]: process.env.REACT_APP_MULTICALL,
    // [ChainId.AMOY]: process.env.REACT_APP_MULTICALL,
  },
  pancakeProfile: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  pancakeRabbits: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  bunnyFactory: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  claimRefund: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  pointCenterIfo: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  bunnySpecial: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  tradingCompetition: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  easterNft: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    // [ChainId.AMOY]: "",
  },
  cakeVault: {
    [ChainId.SEPOLIA]: '0x74da599Afd5333256b8DFfc518321229379f11aB',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_CAKEFAULT,
    // [ChainId.BSC]: process.env.REACT_APP_CAKEFAULT,
    [ChainId.BSC_TESTNET]: '0x3B45D26Ee95dC936997775ab18f7Aa2630941644',
    // [ChainId.POLYGON]: process.env.REACT_APP_CAKEFAULT,
    // [ChainId.AMOY]: process.env.REACT_APP_CAKEFAULT,
  },
  predictions: {
    [ChainId.SEPOLIA]: '0x8d35bEB80d08B2AB749aB306E18B9825b9470d02',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_PREDICTIONS,
    // [ChainId.BSC]: process.env.REACT_APP_PREDICTIONS,
    [ChainId.BSC_TESTNET]: '0x19c82A352995424Ec6783D18a52c78220A7E60A9',
    // [ChainId.POLYGON]: process.env.REACT_APP_PREDICTIONS,
    // [ChainId.AMOY]: process.env.REACT_APP_PREDICTIONS,
  },
  chainlinkOracle: {
    [ChainId.SEPOLIA]: '0x88a23C9420613D9BCC03eCe2fF39C1bA4210a4d1',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_CHAINLINKORACLE,
    // [ChainId.BSC]: process.env.REACT_APP_CHAINLINKORACLE,
    [ChainId.BSC_TESTNET]: '0x6378c109eE2240BB63f595fFa331a66149b6ae2b',
    // [ChainId.POLYGON]: process.env.REACT_APP_CHAINLINKORACLE,
    // [ChainId.AMOY]: process.env.REACT_APP_CHAINLINKORACLE,
  },
  bunnySpecialCakeVault: {
    [ChainId.SEPOLIA]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    // [ChainId.BSC]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    [ChainId.BSC_TESTNET]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    // [ChainId.POLYGON]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    // [ChainId.AMOY]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
  },
  bunnySpecialPrediction: {
    [ChainId.SEPOLIA]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    // [ChainId.BSC]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    [ChainId.BSC_TESTNET]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    // [ChainId.POLYGON]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    // [ChainId.AMOY]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
  },

  // tokens

  weth: {
    [ChainId.SEPOLIA]: '0xFcecf60Fd3954262a80a4460d0389010B8649f00',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_WETH,
    // [ChainId.BSC]: process.env.REACT_APP_WETH,
    [ChainId.BSC_TESTNET]: '0x7F14684443A67DF5dD29f7471D0ea5F673678c32',
    // [ChainId.POLYGON]: process.env.REACT_APP_WETH,
    // [ChainId.AMOY]: process.env.REACT_APP_WETH,
  },
  busd: {
    [ChainId.SEPOLIA]: '0x88d081131287162e9394EBC957F01E2C75CC98Bd',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUSD,
    // [ChainId.BSC]: process.env.REACT_APP_BUSD,
    [ChainId.BSC_TESTNET]: '0x859d7216f75f98a3b83347a7cc75Dc3df789926a',
    // [ChainId.POLYGON]: process.env.REACT_APP_BUSD,
    // [ChainId.AMOY]: process.env.REACT_APP_BUSD,
  },
  pcn: {
    [ChainId.SEPOLIA]: '0x26cf332054007d1E5105285Cb5202257186a2CdF',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_PCN,
    // [ChainId.BSC]: process.env.REACT_APP_PCN,
    [ChainId.BSC_TESTNET]: '0xb0fcf9172198E4Cc891DE99e547E1d97ca426c79',
    // [ChainId.POLYGON]: process.env.REACT_APP_PCN,
    // [ChainId.AMOY]: process.env.REACT_APP_PCN,
  },
  corn: {
    [ChainId.SEPOLIA]: '0xD71E6eBe80590b3e78b1248abF46FA24891AeA34',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_CORN,
    // [ChainId.BSC]: process.env.REACT_APP_CORN,
    [ChainId.BSC_TESTNET]: '0x5901b96EAe0f96d0431D2A66C3957e4b7849898D',
    // [ChainId.POLYGON]: process.env.REACT_APP_CORN,
    // [ChainId.AMOY]: process.env.REACT_APP_CORN,
  },
  PcnLp: {
    [ChainId.SEPOLIA]: '0xf5Aa62278521e9a6ba5208C4d55CB08DF21B392F',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_PCNLP,
    // [ChainId.BSC]: process.env.REACT_APP_PCNLP,
    [ChainId.BSC_TESTNET]: '0x91A9dCEF63444AeB29aAb0ae5C68028f93A025d6',
    // [ChainId.POLYGON]: process.env.REACT_APP_PCNLP,
    // [ChainId.AMOY]: process.env.REACT_APP_PCNLP,
  },
  BusdLp: {
    [ChainId.SEPOLIA]: '0xcdBAF69fD818d046B1090899B630bA6A944B2a74',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUSDLP,
    // [ChainId.BSC]: process.env.REACT_APP_BUSDLP,
    [ChainId.BSC_TESTNET]: '0x73F1F961E3243fDDb751c23ebdc7bdCE73d95779',
    // [ChainId.POLYGON]: process.env.REACT_APP_BUSDLP,
    // [ChainId.AMOY]: process.env.REACT_APP_BUSDLP,
  },
}
