import { ChainId } from '@pancakeswap/sdk'
import sample from 'lodash/sample'

// Array of available nodes to connect to

const networkId = localStorage.getItem('CurrentChain') || process.env.REACT_APP_CHAIN_ID
export const nodes = {
  [ChainId.SEPOLIA]: 'https://ethereum-sepolia-rpc.publicnode.com	',
  // [ChainId.BSC_TESTNET]: 'https://public.stackup.sh/api/v1/node/bsc-testnet',
}
// 'https://sepolia.infura.io/v3/0f2b2cf9a18245e9a104c2b71d9c4daf',

const getNodeUrl = () => {
  return nodes[networkId]
}

export default getNodeUrl
