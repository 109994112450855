import ContractAddress from '../contracts'

export default {
  name: 'Pizzaswap Default List',
  timestamp: '2021-05-06T00:00:00Z',
  version: {
    major: 3,
    minor: 0,
    patch: 0,
  },
  tags: {},
  logoURI: `${window.location.origin}/images/tokens/0x0000000000000000000000000000000000000000.png`,
  keywords: ['pancake', 'default'],
  tokens: [
    {
      name: Number(ContractAddress.networkId) === 97 ? 'WBNB Token' : 'WETH Token',
      symbol: Number(ContractAddress.networkId) === 97 ? 'WBNB' : 'WETH',
      address: `${ContractAddress.weth[ContractAddress.networkId]}`,
      chainId: Number(ContractAddress.networkId),
      decimals: 18,
      logoURI: `${window.location.origin}/images/tokens/${ContractAddress.weth[
        ContractAddress.networkId
      ].toLowerCase()}.png`,
    },
    {
      name: 'Pegged USDT',
      symbol: 'USDT',
      address: `${ContractAddress.busd[ContractAddress.networkId].toLowerCase()}`,
      chainId: Number(ContractAddress.networkId),
      decimals: 18,
      logoURI: `${window.location.origin}/images/tokens/${ContractAddress.busd[
        ContractAddress.networkId
      ].toLowerCase()}.png`,
    },
    {
      name: 'Pizzaswap',
      symbol: 'Pizza',
      address: `${ContractAddress.pcn[ContractAddress.networkId].toLowerCase()}`,
      chainId: Number(ContractAddress.networkId),
      decimals: 18,
      logoURI: `${window.location.origin}/images/tokens/${ContractAddress.pcn[
        ContractAddress.networkId
      ].toLowerCase()}.png`,
    },
  ],
}
